<template>
    <section class="decorated-top-blue">
        <b-container>
            <b-row>
                <b-col lg="8">
                    <h2>{{ payload.title }}</h2>
                    <p>{{ payload.content }}</p>
                    <div v-if="payload.link" class="text-center text-md-left">
                        <b-button class="btn btn-dark" :target="payload.link.target" :href="payload.link.url">
                            {{ payload.link.title }}
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
    export default {
        name: "FeaturedContent",
        props: ['payload']
    }
</script>

<style scoped lang="scss">
    section{
        position: relative;
        background: $primary;
        color: white;
        padding-top: 104px;
        padding-bottom: 104px;
    }

    h2 {
        font-size: 1.75rem;
        @media screen and (min-width: 992px) {
            font-size: 2.75rem;
        }
    }

    h2, p, a {
        margin-bottom: 1.5rem;
        &:last-child {
            margin-bottom: 0;
        }
    }
</style>