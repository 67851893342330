<template>
    <div class="video-listing-item">
        <iframe width="560" height="315" :src="url" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
</template>

<script>
    export default {
        name: "VideoListingItem",
        props: {
            url: String
        }
    }
</script>

<style scoped lang="scss">
    .video-listing-item{
        position: relative;
        padding-bottom: 56.25%;

        iframe{
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
</style>