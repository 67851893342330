<template>
    <page-header
            :image="payload.image.url"
            :title="payload.title"
            :link="payload.link ? payload.link : null"
            :variant="payload.variant ? payload.variant : 'normal'"
    ></page-header>
</template>

<script>
    import PageHeader from "../utility/PageHeader";
    export default {
        name: "Header",
        components: {PageHeader},
        props: ['payload']
    }
</script>

<style scoped lang="scss">

</style>