<template>
    <div ref="num">{{ animatedNumber }}</div>
</template>

<script>
    import { gsap } from "gsap";

    export default {
        name: "AnimatedNumber",
        props: {
            value: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                number: 0,
                tweenedNumber: 0
            }
        },
        mounted() {
            let el = document.addEventListener('scroll', () => {
                if(this.isElementInViewport(this.$refs.num)) {
                    this.number = this.value
                    document.removeEventListener('scroll', el)
                }
            })
        },
        computed: {
            animatedNumber() {
                return this.tweenedNumber.toFixed(0);
            }
        },
        watch: {
            number(newValue) {
                gsap.to(this.$data, { duration: 2.5, tweenedNumber: newValue });
            }
        },
        methods: {
            isElementInViewport (el) {
              if(!el) return false;
                var rect = el.getBoundingClientRect();

                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
                );
            }
        }
    }
</script>

<style scoped>

</style>