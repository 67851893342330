<template>
    <section class="decorated-top-blue-2">
        <b-container>
            <b-row>
                <b-col class="text-center">
                    <h2>Nasze projekty</h2>
                </b-col>
            </b-row>
            <b-row class="mb-5 mt-5">
                <b-col>
                    <entity-block-listing :items="projects"></entity-block-listing>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="text-center">
                    <router-link to="projects">
                        <b-button variant="light">
                            Więcej projektów
                        </b-button>
                    </router-link>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
    import EntityBlockListing from "../utility/EntityBlockListing";
    import { project_acf_mapper } from './../mixins'

    export default {
        name: "FeaturedProjects",
        components: {EntityBlockListing},
        props: ['payload'],
        mixins: [project_acf_mapper],
        mounted() {
          this.$store.dispatch('getAllProjects')
        },
        computed: {
            projects() {
                return this.map_projects_from_acf(this.payload.featured_projects)
            }
        }
    }
</script>

<style scoped lang="scss">
    section {
        background: $primary;
        color: $white;
        padding-top: 104px;
        padding-bottom: 80px;

        @include media-breakpoint-up(lg){
            padding-top: 160px;
            padding-bottom: 160px;
        }
    }
</style>