<template>
    <section class="decorated-top-white-2">
        <b-container>
            <b-row v-if="payload.title">
                <b-col class="text-center">
                    <h2>{{ payload.title }}</h2>
                </b-col>
            </b-row>
            <b-row v-if="payload.numbers.length > 0">
                <b-col cols="12" md="6" lg="3" class="pb-5 pb-lg-0" v-for="(item, i) in payload.numbers" :key="i">
                    <number-info :title="item.name" :value="item.value"></number-info>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
    import NumberInfo from "../utility/NumberInfo";
    export default {
        name: "Numbers",
        components: {NumberInfo},
        props: ['payload']
    }
</script>

<style scoped lang="scss">
    section {
        padding-top: 104px;
        padding-bottom: 140px;

        @media screen and (min-width: 992px) {
            padding-top: 104px;
            padding-bottom: 160px;
        }
    }

    h2 {
        font-size: 1.75rem;
        margin-bottom: 40px;
        color: $primary;
        @media screen and (min-width: 992px) {
            font-size: 2.75rem;
            margin-bottom: 120px;
        }
    }
</style>