<template>
    <div class="text-center">
        <span class="number h1"><animated-number :value="parseInt(value)"></animated-number></span>
        <span class="title">{{ title }}</span>
    </div>
</template>

<script>
    import AnimatedNumber from "./AnimatedNumber";
    export default {
        name: "NumberInfo",
        components: {AnimatedNumber},
        props: ['title', 'value']
    }
</script>

<style scoped lang="scss">
    .number, .title {
        display: block;
    }
    .number {
        color: $primary;
    }
    .title {
        max-width: 196px;
        margin: 0 auto;
    }
</style>