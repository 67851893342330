<template>
    <div>
        <file-listing-item v-for="(item, i) in items" :key="i"
            :name="item.file.filename"
            :date="item.file.date"
            :link="item.file.url"
            :tags="item.tags ? item.tags : []"
        ></file-listing-item>
    </div>
</template>

<script>
    import FileListingItem from "./FileListingItem";
    export default {
        name: "FileListing",
        components: {FileListingItem},
        props: ['items']
    }
</script>

<style scoped lang="scss">

</style>