<template>
    <section class="simple-text-image">
        <b-container>
            <b-row align-h="center" v-if="payload">
                <b-col cols="12" lg="5" :class="payload.image_position === 'left' ? 'order-2 order-lg-1' : 'order-2'">
                    <picture :class="payload.image_position === 'left' ? 'left' : 'right'">
                        <img :src="payload.image.sizes.content_side_image"/>
                    </picture>
                </b-col>
                <b-col cols="12" lg="5" class="content" :class="payload.image_position === 'left' ? 'order-2 pl-lg-5' : 'order-2 order-lg-1 pr-lg-5'" v-html="payload.content"></b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
    export default {
        name: "TextImage",
        props: ['payload']
    }
</script>

<style lang="scss">
    section.simple-text-image{
        color: $black;
        margin-bottom: 80px;

        @include media-breakpoint-up(lg){ margin-bottom: 104px; }

        .content{
            padding-top: 24px;

            h4 {
                margin-bottom: 24px;

                &:last-child{ margin-bottom: 0; }
            }
        }

        picture{
            position: relative;
            display: inline-block;
            width: 100%;
            
            &::after{
                position: absolute;
                content: "";
                background: transparent;
                border: 2px dashed $secondary;
                z-index: -1;
            }

            &.right::after{ top: 8px; right: -8px; bottom: -8px; left: 8px; }
            &.left::after{ top: -8px; right: 8px; bottom: 8px; left: -8px; }

            img { width: 100%; }
        }
    }
</style>
