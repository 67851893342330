<template>
    <section class="simple-text decorated-top-white">
        <b-container>
            <b-row align-h="center">
                <b-col class="content" cols="12" lg="10" v-html="payload.content"></b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
    export default {
        name: "SimpleText",
        props: ['payload']
    }
</script>

<style lang="scss">
    section.simple-text{
        color: $black;
        padding-top: 80px;
        margin-bottom: 80px;

        @include media-breakpoint-up(lg){
            padding-top: 104px;
            margin-bottom: 104px;
        }

        .content{
            h2 {
                margin-bottom: 64px;
    
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
</style>