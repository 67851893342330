<template>
    <div>
        <component v-for="(b,i) in blocks" :is="'block_' + b.acf_fc_layout" :key="i" :payload="b"></component>
    </div>
</template>

<script>
    import Header from './blocks/Header'
    import FeaturedContent from "./blocks/FeaturedContent";
    import Icons from "./blocks/Icons";
    import ContentSections from "./blocks/ContentSections";
    import FeaturedProjects from "./blocks/FeaturedProjects";
    import Numbers from "./blocks/Numbers";
    import Newsletter from "./blocks/Newsletter";
    import LogoGallery from "./blocks/LogoGallery";
    import Comments from "./blocks/Comments";
    import FeaturedContentImage from "./blocks/FeaturedContentImage";
    import Contact from "./blocks/Contact";
    import Team from "./blocks/Team";
    import SimpleText from "./blocks/SimpleText";
    import TextImage from "./blocks/TextImage";
    import Download from "./blocks/Download";
    import VideoContainer from "./blocks/VideoContainer";

    export default {
        name: "Blocks",
        components: {
            'block_header': Header,
            'block_featured_content': FeaturedContent,
            'block_featured_content_image': FeaturedContentImage,
            'block_icons': Icons,
            'block_content_sections': ContentSections,
            'block_featured_projects': FeaturedProjects,
            'block_logo_gallery': LogoGallery,
            'block_numbers': Numbers,
            'block_newsletter': Newsletter,
            'block_comments': Comments,
            'block_contact': Contact,
            'block_team': Team,
            'block_text': SimpleText,
            'block_text_image': TextImage,
            'block_downloads': Download,
            'block_video': VideoContainer,
        },
        props: {
            blocks: {
                type: Array,
                required: true,
            },
        },
    }
</script>

<style scoped>

</style>