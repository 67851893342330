<template>
    <a :href="link" target="_blank" class="file-listing-item">
        <b-row class="no-gutters">
            <b-col cols="auto mr-3">
                <file-icon :icon="extension" />
            </b-col>
            <b-col class="d-flex flex-column">
                <p class="title">{{ name }}</p>
                <span class="date d-md-none mb-2">{{ date }}</span>
                <ul v-if="tags.length > 0" class="tags">
                    <li v-for="(tag, i) in tags" :key="i">{{ tag.name }}</li>
                </ul>
            </b-col>
            <b-col cols="auto">
                <span class="date d-none d-md-flex">{{ date.split(' ')[0] }}</span>
            </b-col>
        </b-row>
    </a>
</template>

<script>
    import FileIcon from '../utility/FileIcon';

    export default {
        name: "FileListingItem",

        components: {
            FileIcon,
        },

        props: {
            name: String,
            date: String,
            link: String,
            tags: Array
        },

        computed: {
            extension: function(){
                return this.name.split('.')[1]
            }
        },
    }
</script>

<style lang="scss">
    .file-listing-item{
        display: block;
        padding: 16px;
        background: $white;
        text-decoration: none;
        transition: 0.3s ease;

        .title{
            @extend %header-5;
            font-weight: 700;
            color: $black;
            margin-bottom: 4px;
        }

        .date{
            @extend %header-6;
            color: $gray-900;
        }

        .file-icon{
            width: 44px;
            height: 44px;
        }

        .tags{
            margin: 0;
            padding: 0;
            list-style-type: none;
            display: inline-flex;
            flex-direction: row;
            list-style-type: none;
            flex-wrap: wrap;

            li{
                position: relative;
                padding: 0 10px;
                margin: 0 8px 8px 0;
                cursor: pointer;
                color: $primary;
                background: $blue-100;
                border-radius: 10px;
                transition: 0.15s ease;
                @extend %header-6;
            }
        }

        &:nth-child(even){ background: #F7F7F7; }
        &:hover{ text-decoration: none; }

        @include media-breakpoint-up(lg){
            padding: 20px 24px;

            &:hover{
                background: rgba($primary, 0.08);
                text-decoration: none;
            }
        }
    }

    .contrast .file-listing-item{
        .tags{
            li{
                background: $white;
                border: 1px solid $primary;
                font-weight: 600;
            }
        }
    }
</style>