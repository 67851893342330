<template>
    <section class="comments">
        <b-container>
            <b-row class="mb-3">
                <b-col class="text-center">
                    <h3>{{ payload.title }}</h3>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <vue-slick-carousel v-bind="settings">
                        <template #prevArrow>
                            <div><icon icon="arrow-left" /></div>
                        </template>
                        <template #nextArrow>
                            <div><icon icon="arrow-right" /></div>
                        </template>

                        <div v-for="(item, i) in payload.comments" :key="i">
                            <b-row align-h="center">
                                <b-col cols="12" lg="10">
                                    <div class="row align-items-end">
                                        <div class="col col-4 d-lg-block d-none text-center">
                                            <img class="pb-5" :src="require('./../../assets/media/' + item.gender_icon + '.svg')"/>
                                        </div>
                                        <div class="col col-lg-8 col-12 text-left">
                                            <p>{{ item.content }}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col d-lg-block d-none">
                                            <img :src="require('./../../assets/media/separator.svg')"/>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col col-lg-8 col-12 offset-lg-4 text-left">
                                            <h6>{{ item.position }}</h6>
                                            <h4>{{ item.name }}</h4>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </vue-slick-carousel>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    // optional style for arrows & dots
    // import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    import Icon from '../utility/Icon';

    export default {
        name: "Comments",

        components: {
            VueSlickCarousel,
            Icon,
        },

        props: ['payload'],

        data() {
            return {
                slide: 0,
                sliding: null,

                settings: {
                    "dots": true,
                    "infinite": true,
                    "slidesToShow": 1,
                    "slidesToScroll": 1,
                    "adaptiveHeight": true,
                },
            }
        }
    }
</script>

<style lang="scss">
    section.comments{
        background: $gray-100;
        padding-top: 104px;
        padding-bottom: 104px;

        .slick{
            &-slider{
                @include media-breakpoint-up(lg){
                    .slick-arrow{
                        opacity: 0;
                        pointer-events: none;
                    }
                
                    &:hover{
                        .slick-arrow{
                            opacity: 1;
                            pointer-events: auto;
                        }
                    }
                }
            }

            &-slide{
            }

            &-arrow{
                &.slick-prev, &.slick-next{
                    display: flex;
                    background: none;
                    border: none;
                    position: absolute;
                    top: 100%;
                    z-index: 2;
                    transform: translateY(-65%);
                    padding: 12px;
                    background: $white;
                    border-radius: 100%;
                    cursor: pointer;
                    &, *{ transition: 0.3s ease; }

                    svg{
                        width: 20px;
                        height: 20px;
                        path{ fill: $black; }
                    }

                    &:hover{
                        svg{ opacity: 1; }
                    }

                    @include media-breakpoint-up(lg){
                        padding: 20px;
                        top: 128px; // setting because of different slide height; on slide change the button is escaping from under the mouse
                        transform: translateY(-50%);

                        svg{
                            opacity: 0.4;
                            width: 24px;
                            height: 24px;
                        }
                    }
                }

                &.slick-prev{ left: 0 }
                &.slick-next{ right: 0; }
            }

            &-dots{
                display: flex !important;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin: 24px 0 0;
                padding: 0;
                list-style-type: none;

                li{
                    background: $gray-300;
                    border-radius: 100%;
                    width: 10px;
                    height: 10px;
                    transition: 0.3s ease;
                    cursor: pointer;

                    &:not(:last-of-type){
                        margin-right: 10px;

                        @include media-breakpoint-up(lg){ margin-right: 20px; }
                    }

                    button{ display: none; }

                    &.slick-active{
                        background: $primary;
                        width: 16px;
                        height: 16px;
                    }
                }

                @include media-breakpoint-up(lg){ margin-top: 48px; }
            }

            img { max-width: 100%; }
        }
    }

</style>