<template>
    <section class="decorated-top-blue-3">
        <b-container>
            <b-row v-if="payload.title">
                <b-col class="text-center">
                    <h2 v-html="payload.title"></h2>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" lg="10" offset-lg="1">
                    <b-row v-if="featured_members.length > 0" class="justify-content-center featured_team_members_container">
                        <b-col cols="6" md="4" v-for="(m,i) in featured_members" :key="'fm_'+i" class="text-center team_member_container">
                            <team-member
                                    :name="m.name"
                                    :position="m.position"
                                    :image="m.image.sizes.person_portrait">
                            </team-member>
                        </b-col>
                    </b-row>
                    <b-row v-if="members.length > 0" class="justify-content-center">
                        <b-col cols="6" md="4"  v-for="(m,i) in members" :key="'m_'+i" class="text-center team_member_container">
                            <team-member
                                    :name="m.name"
                                    :position="m.position"
                                    :image="m.image.sizes.person_portrait">
                            </team-member>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
    import TeamMember from "../utility/TeamMember";
    export default {
        name: "Team",
        components: {TeamMember},
        props: ['payload'],
        computed: {
            members() {
                return this.payload.members.slice(this.payload.featured)
            },
            featured_members() {
                return this.payload.members.slice(0,this.payload.featured)
            }
        }
    }
</script>

<style scoped lang="scss">
    section {
        padding-top: 192px;
        padding-bottom: 180px;

        h2{ margin-bottom: 80px; }

        .featured_team_members_container{ margin-bottom: 80px; }

        .team_member_container {
            margin-bottom: 80px;

            &:nth-last-child(1),
            &:nth-last-child(2) {
                margin-bottom: 0;
            }

            @include media-breakpoint-up(lg) {
                &:nth-last-child(3) {
                    margin-bottom: 0;
                }
            }
        }
    }
</style>