<template>
    <div class="file-icon" v-html="iconCode"></div>
</template>

<script>
    let icons = {}
    const iconFiles = require.context('./files/', true, /\.svg$/i);
    iconFiles.keys().map(k => {
        icons[k.split('/').pop().split('.')[0]] = require('./files/'+k.split('/').pop() + '?inline')
    })

    export default {
        props: {
            icon: {
                type: String,
                required: true,
            },
        },

        computed: {
            iconCode: function() {
                return icons[this.icon];
            }
        },
    }
</script>

<style lang="scss">
    .file-icon{
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &>svg{
            width: 100%;
            height: auto;
        }
    }
</style>
