<template>
    <b-row>
        <b-col v-for="(item, i) in items" :key="i" cols="12" lg="6" class="my-3">
            <video-listing-item :url="item.url" />
        </b-col>
    </b-row>
</template>

<script>
    import VideoListingItem from "./VideoListingItem";
    export default {
        name: "VideoListing",
        components: {VideoListingItem},
        props: ['items']
    }
</script>

<style scoped lang="scss">

</style>