<template>
  <section class="decorated-top-white">
    <b-container>
      <b-row class="mb-5 filter-bar align-items-center align-items-lg-start" align-h="between">
        <b-col cols="auto" order="1">
          <tag-dropdown @change="addTagFilter" :tags="tags"></tag-dropdown>
        </b-col>
        <b-col order="3" order-lg="2" cols="12" lg>
          <selected-tag-cloud :tags="filters.tags" :on-remove="removeTagFilter"></selected-tag-cloud>
        </b-col>
        <b-col order="2" order-lg="3" cols="auto">
          <search-filter v-model="filters.search" @input="page = 0"></search-filter>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <file-listing :items="files"></file-listing>
        </b-col>
      </b-row>
      <b-row class="mt-5 justify-content-center justify-content-lg-end">
        <b-col cols="auto">
          <pagination
              v-if="files.length > 0"
              :number_of_pages="number_of_pages"
              :current_page="page"
              @change="i => this.page = i">
          </pagination>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import FileListing from "../utility/FileListing";
import TagDropdown from "../utility/TagDropdown";
import SelectedTagCloud from "../utility/SelectedTagCloud";
import { tag_filter, search_filter } from '../mixins'
import Pagination from "../utility/Pagination";
import SearchFilter from "../utility/SearchFilter";
export default {
  name: "Download",
  components: {SearchFilter, Pagination, SelectedTagCloud, TagDropdown, FileListing},
  props: ['payload'],
  mixins: [tag_filter, search_filter],
  data() {
    return {
      page: 0,
    }
  },
  computed: {
    number_of_pages() {
      if (!this.filters.search) {
        return Math.ceil(this.all_files.length / 10);
      } else
        return Math.ceil(
            this.all_files
                .filter((n) => this.filterUsingTag(n, true))
                .filter((n) => this.filterUsingSearch(n, true)).length / 10
        );
    },
    all_files() {
      return this.payload.files
    },
    files() {
      return this.all_files
          .filter(n => this.filterUsingTag(n, true))
          .filter(n => this.filterUsingSearch(n, true))
          .slice(this.page * 10, this.page * 10 + 10)
    }
  },
}
</script>

<style scoped lang="scss">
section {
  padding-top: 80px;
  padding-bottom: 80px;
}
</style>