<template>
    <div>
        <picture>
            <img v-if="image" :src="image" />
        </picture>
        <h4 v-if="name" class="mt-4 mb-1">{{ name }}</h4>
        <h6 v-if="position" class="mt-1">{{ position }}</h6>
    </div>
</template>

<script>
    export default {
        name: "TeamMember",
        props: {
            name: String,
            image: String,
            position: String,
        }
    }
</script>

<style scoped lang="scss">
    picture{
        position: relative;
        display: inline-block;
        
        &::after{
            position: absolute;
            top: 8px; right: -8px; bottom: -8px; left: 8px;
            content: "";
            background: transparent;
            border: 2px dashed $secondary;
            z-index: -1;
        }

        img { max-width: 100%; }
    }
</style>