<template>
    <section>
        <b-container>
            <b-row v-if="payload.title" align-v="center">
                <b-col>
                    <h3>{{ payload.title }}</h3>
                </b-col>
                <b-col cols="auto">
                    <button class="video-collapse" v-b-toggle="'videos_' + _uid">
                        <icon icon="triangle-arrow-down" />
                    </button>
                </b-col>
            </b-row>

            <b-collapse :id="'videos_' + _uid" :visible="true">
                <b-row v-if="payload.videos && payload.videos.length > 0">
                    <b-col>
                        <video-listing :items="payload.videos"></video-listing>
                    </b-col>
                </b-row>
            </b-collapse>
        </b-container>
    </section>
</template>

<script>
    import VideoListing from "../utility/VideoListing";
    import Icon from '../utility/Icon';

    export default {
        name: "VideoContainer",

        components: {
            VideoListing,
            Icon,
        },

        props: ['payload'],

        data: function(){
            return{
                visible: true,
            }
        },
    }
</script>

<style lang="scss" scoped>
    section{
        margin-top: 80px;
        margin-bottom: 80px;

        @include media-breakpoint-up(lg){
            margin-top: 104px;
            margin-bottom: 104px;
        }
    }
</style>

<style lang="scss">
    button.video-collapse{
        display: flex;
        background: none;
        border: none;

        .icon{
            transition: 0.3s;

            svg{
                width: 24px;
                height: 24px;
            }
        }

        &[aria-expanded="true"]{
            .icon{ transform: rotate(-180deg); }
        }

        &:focus{
            outline: none;
            svg path{ fill: $primary; }
        }
    }
</style>