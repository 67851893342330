<template>
    <section :class="payload.variant === 'decorated' ? 'decorated-top-white' : ''">
        <b-container>
            <b-row v-if="payload.title">
                <b-col class="text-center">
                    <h2 v-html="payload.title"></h2>
                </b-col>
            </b-row>
            <b-row align-h="center">
                <b-col cols="12" lg="4">
                    <b-row class="mb-5 pb-5" v-if="payload.cards">
                        <b-col cols="12" class="contact_card_container" v-for="(c,i) in payload.cards" :key="i">
                            <contact-card
                              :name="c.name"
                              :position="c.name_prefix"
                              :email="c.email"
                              :phone_number="c.phone"
                              :address="c.address"
                              variant="light"
                            >
                            </contact-card>
                        </b-col>
                    </b-row>
                    <b-row class="mb-5 pb-5" v-if="!payload.cards">
                        <b-col class="content_container" v-html="payload.content"></b-col>
                    </b-row>
                </b-col>

                <b-col cols="12" lg="5" offset-lg="1">
                    <contact-form></contact-form>
                </b-col>
                <b-col v-if="payload.cards" class="content_container" v-html="payload.content" cols="12" lg="10"></b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
    import ContactCard from "../footer/ContactCard";
    import ContactForm from "../utility/ContactForm";

    export default {
        name: "Contact",

        components: {ContactForm, ContactCard},

        props: {
            payload: Object
        },
    }
</script>

<style scoped lang="scss">
    section {
        padding-top: 120px;
        padding-bottom: 144px;
    }

    .content_container {
        color: $black;
    }

    h2 {
        margin-bottom: 104px;
    }

    .contact_card_container {
        margin-bottom: 64px;
        &:last-child {
            margin-bottom: 0;
        }
    }
</style>