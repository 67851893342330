<template>
    <section class="logo-gallery">
        <b-container>
            <b-row v-if="payload.title" class="mb-4 mb-lg-5">
                <b-col class="text-center">
                    <h3>{{ payload.title }}</h3>
                </b-col>
            </b-row>
            <b-row class="align-items-center">
                <b-col>
                    <vue-slick-carousel ref="carousel" v-bind="settings">
                        <div v-for="(img, i) in payload.gallery" :key="i">
                            <picture>
                                <img :src="img.url"/>
                            </picture>
                        </div>
                    </vue-slick-carousel>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    // optional style for arrows & dots
    // import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

    export default {
        name: "LogoGallery",

        components: {
            VueSlickCarousel,
        },

        props: ['payload'],

        data: function(){
            return{
                settings: {
                    "dots": false,
                    "arrows": false,
                    "autoplay": true,
                    "infinite": true,
                    "speed": 500,
                    "slidesToShow": 5,
                    "slidesToScroll": 5,
                    "initialSlide": 0,
                    "responsive": [
                        {
                            "breakpoint": 992,
                            "settings": {
                                "slidesToShow": 3,
                                "slidesToScroll": 3,
                            }
                        },
                        {
                            "breakpoint": 480,
                            "settings": {
                                "slidesToShow": 2,
                                "slidesToScroll": 2,
                            }
                        },
                    ]
                }
            }
        }
    }
</script>

<style lang="scss">
    section.logo-gallery{
        padding-top: 80px;
        padding-bottom: 80px;

        .slick{
            &-track{
                display: flex !important;
                align-items: center;
            }
        }

        @include media-breakpoint-up(lg){
            padding-top: 104px;
            padding-bottom: 144px;
        }

        picture {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;

            img{
                width: 100%;
                height: 100%;
                max-width: 160px;
                max-height: 64px;
                object-fit: contain;

                @include media-breakpoint-up(md){
                    max-height: 80px;
                }
            
                @include media-breakpoint-up(lg){
                    max-width: 176px;    
                    max-height: 112px;
                }
            }
        }
    }
</style>