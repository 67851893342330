<template>
    <section>
        <b-container>
            <b-row align-v="center" align-h="between" class="flex-column-reverse flex-lg-row">
                <b-col cols="12" lg="6">
                    <h3 v-if="payload.title" v-html="payload.title"></h3>
                    <p v-if="payload.content" v-html="payload.content"></p>
                </b-col>
                <b-col v-if="payload.image" cols="12" lg="5">
                    <img :src="payload.image.url"/>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
    export default {
        name: "FeaturedContentImage",
        props: ['payload']
    }
</script>

<style scoped lang="scss">
    section {
        background: $primary;
        color: $white;
        padding-top: 24px;
        padding-bottom: 48px;
        overflow: hidden;

        @include media-breakpoint-up(lg){ padding-top: 48px; }
    }

    img {
        max-width: 100%;
        margin-bottom: 48px;

        @include media-breakpoint-up(lg){
            margin-right: -278px;
            max-width: initial;
            margin-top: 22px;
            margin-bottom: 22px;
        }
    }

    h3 {
        font-size: 1.75rem;
    }

    h3, p {
        margin-bottom: 1.5rem;
        &:last-child {
            margin-bottom: 0;
        }
    }
</style>