<template>
    <section>
        <b-container>
            <div class="listing">
                <b-row class="listing-wrapper">
                    <b-col v-for="(item, i) in payload.icons" :key="i" class="listing-wrapper-item" cols="12" lg="2">
                        <img class="mb-4" :src="item.icon.url"/>
                        <p class="h4">{{ item.name }}</p>
                        <p class="h6">{{ item.description }}</p>
                    </b-col>
                </b-row>
            </div>
        </b-container>
    </section>
</template>

<script>
    export default {
        name: "Icons",
        props: ['payload']
    }
</script>

<style scoped lang="scss">
    section {
        padding: 80px 0 64px;

        @include media-breakpoint-up(lg){
            padding-bottom: 80px;
        }

        .listing{
            overflow-x: auto;
            scroll-snap-type: x mandatory;
            -webkit-overflow-scrolling: touch;
            margin-left: -12px;
            margin-right: -12px;

            &-wrapper{
                @include media-breakpoint-down(lg){ flex-wrap: nowrap; }
                margin-left: 0;
                margin-right: 0;
                
                &-item{
                    &:first-of-type{ scroll-snap-align: start; }
                    &:last-of-type{ scroll-snap-align: end; }

                    text-align: center;
                    scroll-snap-align: center;
                    scroll-snap-stop: normal;

                    .h4{ color: $primary; }

                    @include media-breakpoint-down(lg){
                        min-width: 192px;
                        max-width: 52%;
                        margin-bottom: 16px;
                    }
                }
            }

            /* Let's get this party started */
            &::-webkit-scrollbar {
                width: 15px;
                height: 15px;
                border: 5px solid white;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: $gray-100;
                box-shadow: inset 0 0 0 5px white;
                border: 1px solid white;
                border-radius: 50px;
            }
            
            // /* Handle */
            &::-webkit-scrollbar-thumb {
                border: none;
                border-radius: 50px;
                background-color: $primary;
            }
        }
    }
</style>
